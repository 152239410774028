import React from 'react';
import { LoginForm } from '@decub8/ui';

import { Content, Meta } from '@src/components';
import { useLogin } from '@src/hooks';
import { handleKeyDown } from '@src/utils/form';

const Login: React.FC = () => {
    const props = useLogin();

    return (
        <div
            className="focus:outline-none cursor-default"
            onKeyDown={(e) =>
                !props.disabled && handleKeyDown(e, 'Enter', props.handleClick)
            }
            tabIndex={0}
        >
            <Content>
                <Meta title="Sign in" />
                <div className="min-h-screen flex items-center justify-center pb-[120px]">
                    <LoginForm {...props} />
                </div>
            </Content>
        </div>
    );
};

export default Login;
