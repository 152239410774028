import { KeyEvent } from '@src/ts/types';

export const handleKeyDown = (
    e: KeyEvent,
    key: string,
    callback: () => void,
): void => {
    if (e.key === key) callback();
};

const MAX_NUM_CHARS = 10;
export function shortenEmail(email: string): string {
    const parts = email.split('@');

    if (parts[0].length <= MAX_NUM_CHARS) {
        return email;
    }

    return `${parts[0].substring(0, MAX_NUM_CHARS / 2)}...${parts[0].substring(
        parts[0].length - MAX_NUM_CHARS / 2,
        parts[0].length,
    )}@${parts[1]}`;
}
